<template>
  <v-row no-gutters justify="center">
  <v-col  cols="12" class="text-center">
    <div class="section">
      <h2>Lieux de l'évènement</h2>
    </div>
    <div class="section">
      <p class="paraph2">
        <span>Capitale historique et culturelle</span> de l’ancienne région Franche-Comté, mais également capitale de l’horlogerie française, Besançon est une commune du Doubs, situé à mi-chemin entre les villes de Strasbourg et de Lyon.
      </p>
      <p class="paraph2">Les différentes activités et animations qui composent le festival se retrouvent dans différents lieux emblématiques de la ville de Besançon au coeur de « la Boucle », centre historique de la ville, niché dans un méandre du Doubs. Le festival s’installe donc durant <span>trois jours</span> au milieu d’un patrimoine architectural riche et complet, au cœur même de la vie des bisontins. Quant aux lieux exacts dans lesquelles le festival prendra ses quartiers, ils seront dévoilés prochainement.</p>
      <p class="paraph2">Cette présence au centre de la ville permet un accès facilité au festival, celui-ci étant desservi par deux lignes de Tram, une dizaine de lignes de bus et par VéloCité.</p>
    </div>
    <br>
    <div style="width: 100%"><iframe width="100%" height="600" src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;q=Kursaal%20Besan%C3%A7on+(Festival%20Ludinam)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"><a href="https://www.maps.ie/draw-radius-circle-map/">Google Maps Radius</a></iframe></div><br />
    <div class="section">
        <h3>Je viens en voiture</h3>
        <p>La ville est accessible par trois sorties de l’autoroute A36 :
        <ul class="paraph-list">
            <li>La sortie 4 Besançon Centre.</li>
            <li>La sortie 3 Besançon Ouest.</li>
            <li>La sortie 4.1 Besançon Nord.</li>
        </ul>
        Attention, le centre de la ville étant majoritairement piéton, il est conseillé de se garer en utilisant les parkings installés sur tout le pourtour de la Boucle.</p>
        <h3>Je viens en train</h3>
        <p>Deux gares, reliées entre elles par des navettes régulières permettent d’accéder à Besançon et son centre-ville :</p>
        <ul class="paraph-list">
            <li>La gare Viotte située au centre-ville.</li>
            <li>La gare Franche-Comté TGV (située à Auxon), soit à 15 minutes en navette de la Gare Viotte.</li>
        </ul>
    </div>
  </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Lieux",

  data: () => ({}),
};
</script>

<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
  h2 {
    font-family: $font-titre;
    font-weight: bold;
    color: black;
  }
  h3{
      color: $orange;
      font-weight: bold;
  }
  .paraph2 {
    font-family: $font;
    span {
      color: $orange;
      font-weight: bold;
    }
  }
  .paraph-list{
      list-style: none;
  }
}
</style>
