<template>
  <v-container :fluid="$vuetify.breakpoint.mobile">
    <Dates :currentEvent="$announcedEvent" v-if="$announcedEvent"/>
    <Tarifs/>
    <Lieux/>
    <Securite/>
    <Video/>
  </v-container>
</template>

<script>
// @ is an alias to /src
import Dates from "@/components/Infos-utiles/Dates.vue";
import Tarifs from "@/components/Infos-utiles/Tarifs.vue";
import Lieux from "@/components/Infos-utiles/Lieux.vue";
import Securite from "@/components/Infos-utiles/Securite.vue";
import Video from "@/components/Infos-utiles/Video.vue";

export default {
  name: "Infos-pratiques",
  components: {
    Dates,
    Tarifs,
    Lieux,
    Securite,
    Video,
  },
};
</script>
