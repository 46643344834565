<template>
  <v-row class="text-center" no-gutters justify="center">
    <v-col cols="12">
      <div class="section">
        <h2>Consignes de sécurité</h2>
        <br>
        <div id="consigne" class="vignette cercle effet left_to_right">
          <a href="../../assets/image/securite_ludinam_2017.jpg" target="_blank">
            <div class="img">
              <img src="../../assets/image/securite_ludinam_2017.jpg" alt="img"/>
            </div>
          </a>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Securite",

  data: () => ({}),
};
</script>

<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
  h2 {
    font-family: $font-titre;
    font-weight: bold;
    color: black;
  }

  #consigne {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      .img {
        width: 300px;
        height: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
