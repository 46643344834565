<template>
  <v-row no-gutters align="center" justify="center">
    <v-col cols="12" class="text-center">
      <div class="section">
        <h2>Dates</h2>
      </div>
    </v-col>
    <v-col align-center cols="12" class="text-center">
      <v-card outlined>
        <v-card-title id="card-title">
          Dates et horaires
        </v-card-title>
        <v-card-text>
          Le festival aura lieu du <span>{{ dateEvenement }}</span>.
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";

export default {
  name: "Dates",

  data: () => ({}),
  props: ['currentEvent'],
  computed: {
    dateEvenement() {
      moment.locale('fr')
      return moment(this.currentEvent.dateDebut).format('DD') + " - " + moment(this.currentEvent.dateFin).format('DD') + " " + moment(this.currentEvent.dateFin).format("MMM YYYY");
    },
  }
};
</script>

<style lang="scss" scoped>
$font: "Roboto", sans-serif;
$font-titre: "Roboto Condensed", sans-serif;
$orange: #f1662c;
$bleu: #18b3e2;

.section {
  margin-top: 30px;
  margin-bottom: 30px;
}

main {
  h2 {
    font-family: $font-titre;
    font-weight: bold;
    color: black;
  }

  .v-card__title {
    font-family: $font-titre;
    font-weight: bold;
    justify-content: center;
    color: ghostwhite;
    background-color: $bleu;
  }

  .v-card__text {
    font-family: $font;
    background-color: #eeeeee;

    span {
      font-weight: bold;
    }
  }
}
</style>